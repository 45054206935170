import React, { useState } from "react";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import EventPurchased from "./EventPurchased";
import { EventModel } from "../models/eventModel";

export default function StripeCheckoutForm({
  clientSecret,
  event,
  ...props
}: {
  clientSecret: string;
  event: EventModel,
  [key: string]: any;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (cardNumberElement) {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement,
         
          billing_details: {
            // Include any additional billing details here
            name: 'Cardholder Name',
          },
        },
      });

      if (error) {
        setErrorMessage(error.message ?? "Payment failed");
      } else if (paymentIntent?.status === "succeeded") {
        setPaymentSucceeded(true);
        setIsDialogOpen(true);
      }
    }
  };

  
  const elementStyles = {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a0aec0',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
    
  };

  const cardNumberElementOptions = {
    style: elementStyles,
    placeholder: 'Numero della carta', 
  };

  return (
    <form onSubmit={handleSubmit} {...props}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{
          border: '1px solid #ced4da',
          borderRadius: '8px',
          padding: '10px',
        }}>
          <CardNumberElement  options={cardNumberElementOptions} />
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
       
        <div style={{
          border: '1px solid #ced4da',
          borderRadius: '8px',
          padding: '10px',
        }}>
          <CardExpiryElement options={{ style: elementStyles }} />
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
       
        <div style={{
          border: '1px solid #ced4da',
          borderRadius: '8px',
          padding: '10px',
        }}>
          <CardCvcElement options={{ style: elementStyles }} />
        </div>
      </div>

      <button
        type="submit"
        disabled={!stripe || paymentSucceeded}
        className="px-7 py-3.5 text-sm tracking-wide bg-blue-600 text-white rounded-md hover:bg-blue-700"
      >
        {paymentSucceeded ? "Pagamento avvenuto" : "Paga ora"}
      </button>

      {errorMessage && <div>{errorMessage}</div>}
      <EventPurchased open={isDialogOpen} setOpen={setIsDialogOpen} event={event} />
    </form>
  );
}
