import React, { useState, useEffect } from 'react';

interface DateTimeInputProps {
  showEndTime?: boolean;
  dateLabel?: string;
  startTimeLabel?: string;
  endTimeLabel?: string;
  initialStartDateTime?: Date | null;
  initialEndDateTime?: Date | null;
  validate?: (startDateTime: Date | null, endDateTime: Date | null) => string | null;
  disablePastDates?: boolean;
  onDateTimeChange?: (startDateTime: Date | null, endDateTime: Date | null) => void;
  locale?: string;
}

export default function DateTimeInput({
  showEndTime = false,
  dateLabel = 'Date',
  startTimeLabel = 'Start Time',
  endTimeLabel = 'End Time',
  initialStartDateTime = null,
  initialEndDateTime = null,
  validate,
  disablePastDates = false,
  onDateTimeChange,
  locale = 'en-GB',
}: DateTimeInputProps) {
  const [date, setDate] = useState(initialStartDateTime ? initialStartDateTime.toISOString().split('T')[0] : '');
  const [startTime, setStartTime] = useState(initialStartDateTime ? initialStartDateTime.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' }) : '');
  const [endTime, setEndTime] = useState(initialEndDateTime ? initialEndDateTime.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' }) : '');
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    if (initialStartDateTime) {
      setDate(initialStartDateTime.toISOString().split('T')[0]);
      setStartTime(initialStartDateTime.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' }));
    }
    if (initialEndDateTime) {
      setEndTime(initialEndDateTime.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' }));
    }
  }, [initialStartDateTime, initialEndDateTime, locale]);

  const handleValidation = (newStartDateTime: Date | null, newEndDateTime: Date | null) => {
    if (validate) {
      setValidationError(validate(newStartDateTime, newEndDateTime));
    }
  };

  const handleDateTimeChange = (newDate: string, newStartTime: string, newEndTime: string) => {
    const newStartDateTime = newDate && newStartTime ? new Date(`${newDate}T${newStartTime}`) : null;
    const newEndDateTime = newDate && newEndTime ? new Date(`${newDate}T${newEndTime}`) : null;
    handleValidation(newStartDateTime, newEndDateTime);
    if (onDateTimeChange) {
      onDateTimeChange(newStartDateTime, newEndDateTime);
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value;
    setDate(newDate);
    handleDateTimeChange(newDate, startTime, endTime);
  };

  const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartTime = e.target.value;
    setStartTime(newStartTime);
    handleDateTimeChange(date, newStartTime, endTime);
  };

  const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndTime = e.target.value;
    setEndTime(newEndTime);
    handleDateTimeChange(date, startTime, newEndTime);
  };

  const minDate = disablePastDates ? new Date().toISOString().split('T')[0] : undefined;

   const getDayOfWeek = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const daysOfWeek = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
    return daysOfWeek[date.getDay()];
  };

  return (
        <div className="flex flex-wrap rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md p-2 sm:p-4 gap-4">
      <div className="flex flex-col w-full sm:w-auto">
        <label className="block text-sm font-medium leading-6 text-gray-900">{dateLabel}</label>
        <input
          type="date"
          value={date}
          onChange={handleDateChange}
          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Select date"
          min={minDate}
        />
        <span className="text-gray-500 text-xs">{getDayOfWeek(date)}</span>
      </div>
      
      <div className="flex flex-col w-1/2 sm:w-auto">
        <label className="block text-sm font-medium leading-6 text-gray-900">{startTimeLabel}</label>
        <input
          type="time"
          value={startTime}
          onChange={handleStartTimeChange}
          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Select start time"
        />
      </div>
      
      {showEndTime && (
        <div className="flex flex-col w-1/2 sm:w-auto">
          <label className="block text-sm font-medium leading-6 text-gray-900">{endTimeLabel}</label>
          <input
            type="time"
            value={endTime}
            onChange={handleEndTimeChange}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="Select end time"
          />
        </div>
      )}
      {validationError && <span className="text-red-500 text-sm w-full">{validationError}</span>}
    </div>
  );
}