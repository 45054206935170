/**
 * Converts a value in cents to a formatted currency string.
 * @param {number} value - The value in cents.
 * @param {string} currency - The currency code (e.g., 'EUR', 'USD').
 * @returns {string} - The formatted currency string.
 */
function formatCurrency(value: number, currency: string, intlCode: string): string {
    const formatter = new Intl.NumberFormat(intlCode, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
    });
  
    return formatter.format(value / 100);
  }
  
  export default formatCurrency;