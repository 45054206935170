import React, { useState } from "react";
import StripeDonationCheckoutForm from "../components/StripeDonationCheckoutForm";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../../config/config";
import { useMutation } from "@apollo/client";
import Loading from "../../../components/Loading";
import Modal from "../../../components/ModalDialog";
import DonorsList from "../components/DonorsList";
import { MAKE_DONATION_MUTATION } from "../api/graphql/mutations";

interface DonationIntentVars {
  amount: number;
  paymentInfo: string;
}

const Donations: React.FC = () => {
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null); // 1. Add selectedAmount state
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isPaymentSucceeded, setPaymentSucceeded] = useState<boolean>(false);

  const [makeDonation, { loading, error }] = useMutation<DonationIntentVars>(
    MAKE_DONATION_MUTATION
  );

  const stripePromise = loadStripe(config.stripe.publishableKey!);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Errore durante il pagamento</div>;
  }

  return (
    <div>
      {!isPaymentSucceeded && (
        <div className="flex flex-col items-center space-y-3">
          <div className="text-center max-w-md mx-auto">
            <p className="mt-8 text-pretty text-lg font-normal text-gray-500 sm:text-xl/8">
              GioJoy è un progetto gratuito, realizzato tramite
              autofinanziamento da Giovanna Storace e Fabrzio Guglielmino. Se ti
              piace e vuoi supportare il progetto, puoi fare una donazione.
            </p>
          </div>
          <span className="isolate inline-flex rounded-md shadow-sm">
            {/* 3. Conditionally style buttons based on selectedAmount */}
            <button
              value={500}
              type="button"
              className={`relative inline-flex items-center rounded-l-md px-6 py-4 text-lg font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 ${
                selectedAmount === 500
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-white text-gray-900 hover:bg-gray-50"
              }`}
              onClick={() => setSelectedAmount(500)}
            >
              5 €
            </button>
            <button
              value={1000}
              type="button"
              className={`relative -ml-px inline-flex items-center px-6 py-4 text-lg font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 ${
                selectedAmount === 1000
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-white text-gray-900 hover:bg-gray-50"
              }`}
              onClick={() => setSelectedAmount(1000)}
            >
              10 €
            </button>
            <button
              value={2000}
              type="button"
              className={`relative -ml-px inline-flex items-center px-6 py-4 text-lg font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 ${
                selectedAmount === 2000
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-white text-gray-900 hover:bg-gray-50"
              }`}
              onClick={() => setSelectedAmount(2000)}
            >
              20 €
            </button>
            <button
              value={5000}
              type="button"
              className={`relative -ml-px inline-flex items-center  px-6 py-4 text-lg font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 ${
                selectedAmount === 5000
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-white text-gray-900 hover:bg-gray-50"
              }`}
              onClick={() => setSelectedAmount(5000)}
            >
              50 €
            </button>
            <button
              value={10000}
              type="button"
              className={`relative -ml-px inline-flex items-center px-6 py-4 text-lg font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 ${
                selectedAmount === 10000
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-white text-gray-900 hover:bg-gray-50"
              }`}
              onClick={() => setSelectedAmount(10000)}
            >
              100 €
            </button>
            <button
              value={20000}
              type="button"
              className={`relative -ml-px inline-flex items-center rounded-r-md px-6 py-4 text-lg font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 ${
                selectedAmount === 20000
                  ? "bg-blue-500 text-white hover:bg-blue-600"
                  : "bg-white text-gray-900 hover:bg-gray-50"
              }`}
              onClick={() => setSelectedAmount(20000)}
            >
              200 €
            </button>
          </span>

          <div className="mt-4 space-y-3">
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
                // checked={hideDonor}
                // onChange={(e) => setHideDonor(e.target.checked)}
              />
              <span className="ml-2 text-gray-700">
                Non voglio essere visualizzato nella lista donatori
              </span>
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
                // checked={notifyOnDonation}
                // onChange={(e) => setNotifyOnDonation(e.target.checked)}
              />
              <span className="ml-2 text-gray-700">
                Aggiornami quando qualcun altro effettua una donazione
              </span>
            </label>

            {/* Stripe Donation Checkout Form */}
            <Elements stripe={stripePromise}>
              {selectedAmount && (
                <div className="mt-5 rounded-sm border-s border-gray-50">
                  <h2 className="text-lg font-semibold text-gray-700 mb-5">
                    Effettua la donazione da {selectedAmount / 100} €{" "}
                  </h2>
                  <StripeDonationCheckoutForm
                    makeDonation={(amount, paymentInfo) =>
                      makeDonation({ variables: { amount, paymentInfo } })
                    }
                    amount={selectedAmount}
                    paymentResult={(result) => {
                      setPaymentSucceeded(result);
                      setIsDialogOpen(true);
                    }}
                  />
                </div>
              )}
            </Elements>
          </div>
        </div>
      )}
      <div className="mt-10">
        <DonorsList />
      </div>
      <Modal
        isOpen={isDialogOpen}
        title={"Grazie ❤️"}
        buttonLabel=""
        onCancel={() => setIsDialogOpen(false)}
        onClose={() => setIsDialogOpen(false)}
      >
        <div className="col-span-1 flex flex-col  rounded-lg bg-white text-center ">
          <h1 className="">Grazie per il tuo contributo</h1>
          <img
            src={`${process.env.PUBLIC_URL}/grazie_animated.gif`}
            alt="Grazie"
          ></img>
        </div>
      </Modal>
    </div>
  );
};

export default Donations;
