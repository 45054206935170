import { useMemo } from "react";
import { useEvents } from "./useEvents";

export interface EventFilters {
  first: number;
  after: string | null;
  month: number;
  year: number;
  latitude: number;
  longitude: number;
  radius: number;
}

export const useFilteredEvents = (filters: EventFilters) => {
  const startDate = useMemo(
    () => new Date(Date.UTC(filters.year, filters.month, 1)),
    [filters.year, filters.month]
  );
  
  const endDate = useMemo(
    () => new Date(Date.UTC(filters.year, filters.month + 1, 0)),
    [filters.year, filters.month]
  );

  const { events, loading, error, refetch } = useEvents({
    first: filters.first,
    startDate: startDate,
    endDate: endDate,
    latitude: filters.latitude,
    longitude: filters.longitude,
    radius: filters.radius,
  });

  return { events, loading, error, refetch};
};
