import React, { useState } from "react";
import { LOGIN_ROUTE } from "../../../routes";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD_MUTATION } from "../api/graphql/mutations";
import Loading from "../../../components/Loading";
import ErrorPanel from "../../../components/ErrorPanel";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { LOGO_URL } from "../../../consts";

const PasswordRecovery: React.FC = () => {
  const { token } = useParams();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password1, setPassword1] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [resetPassword, { loading, error: forgotError }] = useMutation(
    RESET_PASSWORD_MUTATION
  );

  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);

    if (password !== password1) {
      setError("Le password non coincidono");
      return;
    }

    try {
      await resetPassword({ variables: { token, password } });
      setSuccess(true);
    } catch (err) {
      setSuccess(false);
      setError(
        "Il cambio password non è andato a buon fine. Riprova più tardi."
      );
    }
  };

  if (success) {
    return (
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            alt="GioJoy"
            src={LOGO_URL}
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Password cambiata
          </h2>
        </div>
        <div className="mt-10 sm:mx">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <a
              href={LOGIN_ROUTE}
              className="block text-center p-4 rounded-md hover:bg-indigo-50 transition-colors duration-200"
            >
              <p className="text-gray-900 mb-2">
                La tua password è stata cambiata con successo.
              </p>
              <span className="text-indigo-600 hover:text-indigo-500 font-medium underline">
                Clicca qui per effettuare il login
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (forgotError) {
    return (
      <ErrorPanel message="Qualcosa non ha funzionato, riprova più tardi" />
    );
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            alt="GioJoy"
            src={LOGO_URL}
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Cambio password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <div className="relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      required
                      minLength={8}
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />{" "}
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      {showPassword ? (
                        <EyeSlashIcon className="h-7 w-7 text-gray-400 hover:text-gray-500" />
                      ) : (
                        <EyeIcon className="h-7 w-7 text-gray-400 hover:text-gray-500" />
                      )}
                    </button>
                  </div>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  La password deve avere almeno 8 caratteri, includendo una
                  maiuscola, una minuscola, un numero e un simbolo
                </p>
              </div>

              <div>
                <label
                  htmlFor="password1"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Ripeti password
                </label>
                <div className="mt-2">
                  <div className="relative">
                  <input
                    id="password1"
                    name="password1"
                    type={showPassword1 ? "text" : "password"}
                    required
                    minLength={8}
                    autoComplete="new-password"
                    value={password1}
                    onChange={(e) => setPassword1(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <button
                      type="button"
                      onClick={() => setShowPassword1(!showPassword1)}
                      className="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      {showPassword1 ? (
                        <EyeSlashIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" />
                      ) : (
                        <EyeIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" />
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {error && <div className="text-red-500 text-sm">{error}</div>}

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Invia
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordRecovery;
