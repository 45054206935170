import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";

export default function StripeDonationCheckoutForm({
  amount,
  makeDonation,
  paymentResult,
  ...props
}: {
  amount: number;
  makeDonation: (amount: number, paymentInfo: string) => Promise<any>;
  paymentResult: (result: boolean) => void;
  [key: string]: any;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (cardNumberElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          // Include any additional billing details here
          name: "",
        },
      });

      if (error) {
        setErrorMessage(error.message ?? "Payment failed");
        return;
      }

      const result = await makeDonation(amount, paymentMethod?.id);

      if (result.errors && result.errors.length > 0) {
        // Handle specific errors returned from makeDonation
        setErrorMessage(result.errors.join(", "));
        paymentResult(false);
      } else {
        // Successful donation
        paymentResult(true);
      }
    }
  };
  const elementStyles = {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#a0aec0",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  const cardNumberElementOptions = {
    style: elementStyles,
    placeholder: "Numero della carta",
  };

  return (
    <form onSubmit={handleSubmit} {...props}>
      <div style={{ marginBottom: "20px" }}>
        <div
          style={{
            border: "1px solid #ced4da",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <CardNumberElement options={cardNumberElementOptions} />
        </div>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <div
          style={{
            border: "1px solid #ced4da",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <CardExpiryElement options={{ style: elementStyles }} />
        </div>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <div
          style={{
            border: "1px solid #ced4da",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <CardCvcElement options={{ style: elementStyles }} />
        </div>
      </div>

      <div className="flex items-center justify-between mt-4">
        <button
          type="submit"
          disabled={!stripe}
          className="px-7 py-3.5 text-sm tracking-wide bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Paga ora
        </button>
        <span className="text-sm text-gray-500 flex items-center">
          Pagamento sicuro gestito da
          <img
            src={`${process.env.PUBLIC_URL}/stripe_logo.png`}
            alt="Logo di Stripe per pagamenti sicuri"
            className="h-7 ml-1 object-contain"
          />
        </span>
      </div>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
}
