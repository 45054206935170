const config = {
    api: {
      graphql: process.env.REACT_APP_GRAPHQL_API_URL,
      rest: process.env.REACT_APP_REST_API_URL,
    },
    google: {
      mapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    },
    stripe: {
      publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
      secretKey: process.env.REACT_APP_STRIPE_SECRET_KEY

    }
};

export default config;