import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { FORGOT_PASSWORD_MUTATION } from "../api/graphql/mutations";
import Loading from "../../../components/Loading";
import ErrorPanel from "../../../components/ErrorPanel";
import { LOGO_URL } from "../../../consts";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [forgotPassword, { loading, error: forgotError }] = useMutation(
    FORGOT_PASSWORD_MUTATION
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);

    if (!email) {
      setError("Inserisci un indirizzo e-mail valido");
      return;
    }
    try {
      await forgotPassword({ variables: { email } });
      setSuccess(true);
    } catch (error) {
      setError("Errore durante l'invio dell'email");
    }
  };

  if (success) {
    return (
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            alt="GioJoy"
            src={LOGO_URL}
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Email inviata
          </h2>
        </div>
        <div className="mt-10 sm:mx">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <p className="text-gray-900 text-center">
              Ti abbiamo inviato un'email con le istruzioni per reimpostare la
              tua password.
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (forgotError) {
    return (
      <ErrorPanel message="Qualcosa non ha funzionato, riprova più tardi" />
    );
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            alt="GioJoy"
            src={LOGO_URL}
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Password dimenticata
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Inserisci l'indirizzo e-mail utilizzato al momento
                  dell'iscrizione e riceverai il link per reimpostare la tua
                  password.
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {error && <div className="text-red-500 text-sm">{error}</div>}

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Invia
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
