import EventCard from "../components/EventCard";
import EventsFilterHeader from "../components/EventsFilterHeader";
import { EventModel } from "../models/eventModel";
import EmptyEventsList from "../components/EmptyEventsList";
import { useFilteredEvents } from "../hooks/useFilteredEvents";
import Loading from "../../../components/Loading";
import ErrorPanel from "../../../components/ErrorPanel";
import { filtersVar } from "../api/eventsFilter";
import { useReactiveVar } from "@apollo/client";

export default function Events() {
  const filters = useReactiveVar(filtersVar);
  const {
    events,
    loading,
    error,
    refetch,
  } = useFilteredEvents(filters);
  
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPanel message="Riprova più tardi." />;
  }



  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      <EventsFilterHeader filters={filters} />
      <div className="mb-10"> </div>

      {events.length === 0 ? (
        <EmptyEventsList />
      ) : (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {events.map((event: EventModel) => (
            <li
              key={event.id}
              className="col-span-1 bg-white shadow h-full rounded-3xl border border-gray-300 overflow-hidden"
              // onClick={() => navigate(`/events/${event.id}`)}
            >
              <EventCard
                event={event}
                detailCard={true}
                refetch={refetch}
            
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
