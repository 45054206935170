import { GeoLocation } from "../../common/models/geoLocation";
import { PriceModel } from "./priceModel";
import { SubscriberModel } from "./subscriberModel";

export interface AgeRange {
  min: number;
  max: number;
}

export function getAgeRangeHumanReadable(ageRange: AgeRange): string {
  if (ageRange.min === 18 && ageRange.max === 99) {
    return "senza limiti di età";
  }

  return `${ageRange.min} / ${ageRange.max} anni (flessibile)`;
}

export interface InputPriceModel {
  amount: number;
  currency: string;
}

interface CommonEventFields {
  title: string;
  images: string[]; // Array of image URLs or IDs
  date: Date;
  endDate: Date | null; // Optional, as it may not be present for all events
  description: string;
  price: InputPriceModel;
  kidPrice: InputPriceModel;
  location: GeoLocation;
  allowKids: boolean;
  ageRange: AgeRange;
  tags: string[]; // Array of tag names
  kidsAgeRange?: AgeRange;
  maxSubscribers: number; // Optional, as it may not be present for all events
}

// Interface for Creating an Event
export interface CreateEventInput extends CommonEventFields {}

// Interface for Updating an Event
export interface UpdateEventInput extends Partial<CommonEventFields> {
  id: string; // Required to identify the event
}

export interface OwnerUser {
  id: string;
  name: string;
  profileImage: string;
}

export interface EventModel {
  id: string;
  title: string;
  images: string[]; // Updated to an array of strings
  date: Date;
  endDate: Date | null
  description: string;
  price: PriceModel;
  kidPrice: PriceModel;
  location: GeoLocation;
  allowKids: boolean;
  kidsAgeRange?: AgeRange; // Optional, as it may not be present for all events
  ageRange: AgeRange;
  tags: string[]; // Array of strings to represent tags
  subscribers: SubscriberModel[];
  maxSubscribers: number;
  remainingSeats: number;
  userSubscriptionStatus:
    | "SUBSCRIBED"
    | "UNSUBSCRIBED"
    | "PENDING"
    | "WAITING_LIST";
  owner: OwnerUser | null;
}
