import { XCircleIcon } from "@heroicons/react/24/outline";
import { LOGIN_ROUTE } from "../routes";
import { LockClosedIcon } from "@heroicons/react/20/solid";

export default function AskToLoginPopup({
  closePopup,
}: {
  closePopup: () => void;
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white p-6 rounded-md shadow-md">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={closePopup}
        >
          <XCircleIcon className="h-6 w-6" />
        </button>
        <div className="flex flex-row items-center">
          <LockClosedIcon className="h-8 w-8 text-indigo-600" />
          <h2 className="text-lg font-semibold mb-4 ml-10">
            Autenticazione necessaria
          </h2>
        </div>
        <p className="mb-6">
          Devi essere autenticato per poter iscriverti all'evento.
        </p>
        <div className="flex  justify-end">
          <a
            className="w-full md:w-auto rounded-md bg-indigo-600 px-6 py-3 text-m font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            href={`${LOGIN_ROUTE}?redirectTo=${window.location.pathname}`}
            onClick={closePopup}
          >
            Login {" 👉 "} 
          </a>
        </div>
      </div>
    </div>
  );
}
