import { useState } from "react";

export function GooglePlayButton({
  color = "black",
}: {
  color?: "black" | "white";
}) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative inline-block">
      <button aria-label="Download on the Play Store">
        <img
          src={process.env.PUBLIC_URL + "/google-play-badge.png"}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          alt="Play Store"
          className="h-12"
        />
      </button>
      {showTooltip && (
        <div className="absolute bottom-full mb-2 w-48 p-2 text-center text-white bg-black rounded-md shadow-lg">
          Coming soon, stay tuned!
        </div>
      )}
    </div>
  );
}
