import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { LOGIN_ROUTE } from "../../../routes";

const Welcome: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow flex flex-col justify-center items-center text-center">
        <h3 className="mt-2 text-5xl font-semibold text-gray-900">
          Benvenuto nella comunità più carica di energia del web!
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Inizia a scoprire gli eventi più vicini a te.
        </p>
        <div className="mt-6">
          <a
            href={LOGIN_ROUTE}
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <ArrowRightCircleIcon
              aria-hidden="true"
              className="-ml-0.5 mr-1.5 h-5 w-5"
            />
            Accedi
          </a>
        </div>
      </div>
      
    </div>
  );
};

export default Welcome;