import React, { createContext, useContext, useState } from 'react';
import { UserModel } from '../features/user/models/userModel';

interface UserCacheContextType {
  user: UserModel | null;
  setUser: (user: UserModel) => void;
  lastFetched: number;
  setLastFetched: (timestamp: number) => void;
}

const UserCacheContext = createContext<UserCacheContextType | undefined>(undefined);

interface UserCacheProviderProps {
  children: React.ReactNode;
}

export const UserCacheProvider: React.FC<UserCacheProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserModel | null>(null);
  const [lastFetched, setLastFetched] = useState<number>(0);

  return (
    <UserCacheContext.Provider value={{ user, setUser, lastFetched, setLastFetched }}>
      {children}
    </UserCacheContext.Provider>
  );
};

export const useUserCache = () => {
  const context = useContext(UserCacheContext);
  if (context === undefined) {
    throw new Error('useUserCache must be used within a UserCacheProvider');
  }
  return context;
};