import React, { useRef } from "react";
import {
  Autocomplete,
  useJsApiLoader,
  Libraries,
} from "@react-google-maps/api";
import config from "../config/config";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

const googleMapsApiKey = config.google.mapsApiKey;
//const libraries = ["places"]; //
const libraries: Libraries = ["places"];

interface AddressAutocompleteProps {
  onSelect: (latitude: number, longitude: number, name: string) => void;
  placeholder?: string;
  value?: string;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  onSelect,
  placeholder,
  value,
}) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [isValid, setIsValid] = React.useState(false);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey!,
    libraries,
  });

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current?.getPlace();
    if (place && place.geometry) {
      const latitude = place.geometry.location?.lat();
      const longitude = place.geometry.location?.lng();
      const name = place.formatted_address;
      setIsValid(true);
      onSelect(latitude!, longitude!, name!);
    }
  };
  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <Autocomplete
      className=" rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600"
      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
      onPlaceChanged={handlePlaceChanged}
      options={{
        componentRestrictions: { country: "it" }, // Restrict to Italy
      }}
    
    >
      <div className="relative flex items-center">
        <input
          type="text"
          placeholder={placeholder ?? "Scrivi l'indirizzo dell'evento"}
          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 pr-10 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          defaultValue={value}
        />
        {isValid && (
          <CheckCircleIcon className="absolute right-2 h-5 w-5 text-green-600" />
        )}
      </div>
    </Autocomplete>
  );
};

export default AddressAutocomplete;
