interface SvgIconProps {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
  stroke?: string;
  viewBox?: string;
  children: React.ReactNode;
}

export const SvgIcon: React.FC<SvgIconProps> = ({ 
  className,
  children,
  width = 24,
  height = 24,
  color = "currentColor",
  stroke = "none",
  viewBox = "0 0 24 24"
}) => {
  return (
    <svg 
      width={width}
      height={height}
      viewBox={viewBox}
      fill={color}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden="true"
      role="img"
    >
      {children}
    </svg>
  );
};