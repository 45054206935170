import React from 'react';
import LandingHero from '../components/LandingHero';


const Landing: React.FC = () => {
  return (
    <>
        <LandingHero />
        
        {/*<Features />
        <Testimonials />
        <Pricing />
        <FAQ />
        <CTA /> */}
    </>
  );
};

export default Landing;