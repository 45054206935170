import React, { useState } from "react";
import { calculateAge } from "../../../utility/date";
import { EventModel } from "../models/eventModel";
import formatCurrency from "../../../utility/currencies";
import { gql, useMutation } from "@apollo/client";
import { SUBSCRIBE_EVENT_MUTATION } from "../api/eventService";
import {
  CHECKOUT_ROUTE,
  MY_EVENTS_ROUTE,
  PROFILE_ROUTE,
} from "../../../routes";
import ErrorPanel from "../../../components/ErrorPanel";
import SpinnerWithLabel from "../../../components/SpinnerWithLabel";
import { SubscriberModel } from "../models/subscriberModel";
import { UserModel } from "../../user/models/userModel";

interface Child {
  id: string;
  firstName: string;
  lastName: string;
  gender: string;
  birthDate: string;
}

interface EventSubscribeData {
  eventId: string;
  childrenIds: string[];
  guests: number;
}

interface FormErrors {
  eventId?: string;
  childrenIds?: string;
  guests?: string;
}

interface ChildSelectProps {
  children: Child[];
  selectedChildren: string[];
  onChildSelect: (childId: string) => void;
}

const EventSubscribeDetails = ({
  event,
  user,
}: {
  event: EventModel;
  user: UserModel | null;
}) => {
  const [subscribeResult, setSubscribeResult] = useState<
    "SUBSCRIBING" | "SUBSCRIBED" | "PURCHASING"
  >("SUBSCRIBING");

  const [subscribeEvent, { loading }] = useMutation(SUBSCRIBE_EVENT_MUTATION, {
    update: (cache, { data: { subscribeEvent } }) => {
      const eventId = cache.identify({ __typename: "Event", id: event.id });

      // Read the existing event from the cache
      const eventInCache = cache.readFragment<{
        subscribers: SubscriberModel[];
      }>({
        id: eventId,
        fragment: gql`
          fragment EventWithSubscribers on Event {
            subscribers {
              __typename
              id
              userId
              name
              profilePicture
              amount
              timestamp
              guests
              childrenCount
              childrenList {
                __typename
                childId
                name
                age
              }
            }
            userSubscriptionStatus
          }
        `,
      });

      if (eventInCache) {
        // Create a new subscriber object
        const newSubscriber = {
          __typename: "EventSubscriber",
          id: user?.userId,
          name: user?.firstName,
          profilePicture: user?.profileImage,
          amount: 0, // Set appropriate values
          timestamp: new Date().toISOString(),
          guests: formData.guests,
          childrenCount: formData.childrenIds.length,
        };

        // Write the new subscriber to the cache with the correct fragment
        const newSubscriberRef = cache.writeFragment({
          data: newSubscriber,
          fragment: gql`
            fragment NewSubscriber on EventSubscriber {
              id
              name
              profilePicture
              amount
              timestamp
              guests
              childrenCount
              childrenList {
                __typename
                childId
                name
                age
              }
              __typename
            }
          `,
        });

        // Modify the event in the cache
        cache.modify({
          id: eventId,
          fields: {
            subscribers(existingSubscriberRefs = []) {
              return [...existingSubscriberRefs, newSubscriberRef];
            },
            userSubscriptionStatus() {
              return "SUBSCRIBED";
            },
          },
        });
      }
    },
  });

  const [formData, setFormData] = useState<EventSubscribeData>({
    eventId: event.id,
    childrenIds: [],
    guests: 0,
  });

  const [error, setError] = useState<string | null>(null);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const handleGuestChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, guests: Number(e.target.value) });
  };
  const [payLink, setPayLink] = useState<string | null>(null);

  const handleChildSelect = (childId: string) => {
    setFormData((prev) => {
      const isSelected = prev.childrenIds.includes(childId);
      const updatedChildrenIds = isSelected
        ? prev.childrenIds.filter((id) => id !== childId)
        : [...prev.childrenIds, childId];
      return { ...prev, childrenIds: updatedChildrenIds };
    });
  };

  const onSubscribe = async () => {
    // Simple validation (optional)
    if (formData.guests < 0) {
      setFormErrors({ guests: "Numero di ospiti non valido." });
      return;
    }

    try {
      const response = await subscribeEvent({
        variables: {
          eventId: formData.eventId,
          guests: formData.guests,
          children: formData.childrenIds,
        },
      });

      if (response.data?.subscribeEvent.success) {
        if (response.data?.subscribeEvent.needPayment) {
          setSubscribeResult("PURCHASING");
          setPayLink(
            `${CHECKOUT_ROUTE}/${event.id}?clientSecret=${response.data?.subscribeEvent.stripeClientSecret}`
          );
        } else {
          setSubscribeResult("SUBSCRIBED");
        }
      } else {
        setError("Errore durante l'iscrizione. Riprova più tardi.");
      }
    } catch (err) {
      if (err instanceof Error && "graphQLErrors" in err) {
        const graphQLErrors = (err as any).graphQLErrors;
        for (let error of graphQLErrors) {
          if (error.extensions?.code === "ERR_EVENT_FULL") {
            setError(
              "L'evento ha raggiunto il numero massimo di partecipanti."
            );
            return;
          }
        }
      } else {
        console.error("Errore durante l'iscrizione:", err);
        setError("Errore durante l'iscrizione. Riprova più tardi.");
      }
    }
  };

  if (error) {
    return <ErrorPanel title="Iscrizione non permessa" message={error} />;
  }

  if (subscribeResult === "SUBSCRIBED") {
    return (
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
        <h2 className="text-2xl font-semibold text-gray-900">
          Iscrizione completata!
        </h2>
        <p className="mt-4">
          Hai completato l'iscrizione all'evento "{event.title}". Grazie per
          esserti iscritto!
        </p>
        <a href={MY_EVENTS_ROUTE} className="mt-6 text-indigo-600">
          Vedi i tuoi eventi
        </a>
      </div>
    );
  }

  if (subscribeResult === "PURCHASING") {
    return (
      <>
        <a
          href={payLink!}
          className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Clicca qui per completare l'acquisto
        </a>
      </>
    );
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
      <p>
        Indica se porti ospiti {event.allowKids ? "e/o i tuoi figli." : "."}
      </p>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <h3 className="text-sm font-medium text-gray-900">Quanti ospiti?</h3>
        <select
          id="guests"
          name="guests"
          value={formData.guests}
          onChange={handleGuestChange}
          className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        >
          <option value={0}>Nessun ospite</option>
          <option value={1}>1 ospite</option>
          <option value={2}>2 ospiti</option>
          <option value={3}>3 ospiti</option>
          <option value={4}>4 ospiti</option>
          <option value={5}>5 ospiti</option>
        </select>
        {formErrors.guests && (
          <p className="text-red-500 text-sm">{formErrors.guests}</p>
        )}

        {event.allowKids && !user?.children && (
          <div className="flex flex-col items-center">
            <p className="text-sm font-medium text-gray-900 mt-5 mb-3 text-center">
              Questo evento è aperto anche ai figli. <br />
              Se vuoi portarli con te, aggiungili al tuo profilo.
            </p>
            <a
              className="mt-6 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              href={`${PROFILE_ROUTE}?edit`}
            >
              Aggiungi i tuoi figliə al profilo
            </a>
          </div>
        )}

        {event.allowKids && user?.children && user.children.length > 0 && (
          <>
            <h3 className="text-sm font-medium text-gray-900 mt-5 mb-3">
              Porti figliə?
              {event.kidPrice.amount > 0
                ? `(+ ${formatCurrency(
                    event.kidPrice.amount,
                    "EUR",
                    "it-IT"
                  )} cad`
                : ""}
            </h3>
            <ChildSelect
              children={user.children.map<Child>((c) => ({
                id: c.childId,
                firstName: c.firstName,
                lastName: c.lastName,
                gender: c.gender!,
                birthDate: c.birthDate!,
              }))}
              selectedChildren={formData.childrenIds}
              onChildSelect={handleChildSelect}
            />
          </>
        )}

        <button
          className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={onSubscribe}
          disabled={loading}
        >
          {loading && <SpinnerWithLabel label="Iscrizione ..." />}
          {!loading &&
            (event.price.amount === 0
              ? "Iscriviti"
              : `Iscriviti per ${formatCurrency(
                  event.price.amount +
                    event.price.amount * formData.guests +
                    formData.childrenIds.length * event.kidPrice.amount,
                  "EUR",
                  "it-IT"
                )}`)}
        </button>
        {/* {error && (
          <p className="text-red-500 mt-2">Errore durante l'iscrizione.</p>
        )}  */}
      </div>
    </div>
  );
};

export default EventSubscribeDetails;

export const ChildSelect: React.FC<ChildSelectProps> = ({
  children,
  selectedChildren,
  onChildSelect,
}) => {
  return (
    <fieldset>
      <legend className="sr-only">
        Vuoi portare i tuoi figli? Seleziona chi vuoi portare
      </legend>
      <div className="space-y-5">
        <div className="space-y-4">
          {children.map((child) => (
            <div key={child.id} className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id={`child-${child.id}`}
                  name={`child-${child.id}`}
                  type="checkbox"
                  aria-describedby={`child-description-${child.id}`}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  checked={selectedChildren.includes(child.id)}
                  onChange={() => onChildSelect(child.id)}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor={`child-${child.id}`}
                  className="font-medium text-gray-900"
                >
                  {`${child.firstName} ${child.lastName}`}
                </label>
                <p
                  id={`child-description-${child.id}`}
                  className="text-gray-500"
                >
                  {`${
                    child.gender === "MALE" ? "Maschio" : "Femmina"
                  } ${calculateAge(child.birthDate)} anni`}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </fieldset>
  );
};
