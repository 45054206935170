import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

export interface ThreeDotsItem {
  title: string;
  icon: JSX.Element;
  onClick: () => void;
}

interface ThreeDotsMenuProps {
  title: string;
  items: ThreeDotsItem[];
}

export default function ThreeDotsMenu({ title, items }: ThreeDotsMenuProps) {
  return (
    <div className="flex flex-shrink-0 self-center">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="flex items-center justify-center h-8 w-8 rounded-full bg-blue-500/50">
            <EllipsisVerticalIcon aria-hidden="true" className="h-8 w-8 text-white" />
          </MenuButton>
        </div>

        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            {items.map((item) => (
              <MenuItem
                key={item.title}
                as="button"
                onClick={item.onClick}
                className="w-full flex px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
              >
                {item.icon}
                <span>{item.title}</span>
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
}
