import {
  ArrowUpOnSquareIcon,
  DocumentDuplicateIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import useGetProfile from "../hooks/useGetProfile";
import { useEffect, useState } from "react";
import styles from "./Profile.module.css"; // Import the CSS module
import ChildrenList from "../common/ChildrenList";
import { calculateAge, getZodiacSign } from "../../../utility/date";
import { useMutation } from "@apollo/client";
import { QRNormal } from "react-qrbtf";
import { UPDATE_USER, uploadUserProfileImage } from "../api/userService";
import useFileUpload from "../../../hooks/useFileUpload";
import Loading from "../../../components/Loading";
import ErrorPanel from "../../../components/ErrorPanel";
import { useSearchParams } from "react-router-dom";

interface UserData {
  profileImage: string;
  firstName: string;
  lastName: string;
  birthDate: string | null;
  phoneNumber: string;
  gender: string;
  allowNewEventEmails: boolean;
}

function formatBirthDate(dateString: string | null | undefined): string {
  if (!dateString) {
    return "";
  }
  return dateString.split("T")[0];
}

export default function Profile() {
  return (
    <div className="col-span-full">
      <div className=" w-auto mx-auto bg-white rounded-lg overflow-hidden ">
        <ProfileDataPanel />
      </div>
    </div>
  );
}

function ProfileDataPanel() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isEditParamPresent = searchParams.has("edit");

  const { user, loading, error, refetch } = useGetProfile();
  const [iconClicked, setIconClicked] = useState(false);
  const [isEdit, setIsEdit] = useState(isEditParamPresent);
  const [updateUser] = useMutation(UPDATE_USER);
  const [formData, setFormData] = useState<UserData>({
    profileImage: "",
    firstName: "",
    lastName: "",
    birthDate: null,
    phoneNumber: "",
    gender: "Altro",
    allowNewEventEmails: false,
  });
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  const {
    uploadedUrl,
    error: uploadError,
    isUploading,
    handleFileUpload,
  } = useFileUpload("", uploadUserProfileImage);

  const handleCopy = () => {
    if (user?.referralCode) {
      navigator.clipboard.writeText(
        baseUrl + "/signup?ic=" + user.referralCode
      );
      setIconClicked(true);
      setTimeout(() => setIconClicked(false), 2000);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "birthDate") {
      // Convert the date to ISO format for server
      setFormData({ ...formData, [name]: value });

      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleIsEdit = () => {
    const newIsEdit = !isEdit;
    setIsEdit(newIsEdit);

    if (newIsEdit) {
      searchParams.set("edit", "true");
    } else {
      searchParams.delete("edit");
    }

    setSearchParams(searchParams);
  };

  const onSaveProfile = async () => {
    try {
      formData.birthDate = new Date(formData.birthDate!).toISOString();
      await updateUser({ variables: formData });
      await refetch();
      setIsEdit(false);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const translateGender = (gender: string | null) =>
    gender === "OTHER" || gender === null
      ? "Altro"
      : gender === "MALE"
      ? "Maschio"
      : "Femmina";

  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        birthDate: user.birthDate || null,
        phoneNumber: user.phoneNumber || "",
        profileImage: user.profileImage || "",
        gender: translateGender(user.gender),
        allowNewEventEmails: user.meta.allowNewEventEmails,
      });
    }
  }, [user]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPanel />;
  }

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="px-4 py-6 sm:px-6 flex justify-between items-center">
        <div>
          <h3 className="text-base font-semibold text-gray-900">
            Il tuo profilo
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Gestisci qui le tue informazioni personali.
          </p>
        </div>

        {isEdit && (
          <div className="flex items-end justify-end gap-2">
            <button
              className="rounded-md hover:text-gray-500 hover:bg-gray-100 bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-white-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 border border-gray-300"
              onClick={handleIsEdit}
            >
              Annulla
            </button>
            <button
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={onSaveProfile}
            >
              Salva
            </button>
          </div>
        )}

        {!isEdit && (
          <button
            className="text-gray-400 hover:text-gray-500 focus:outline-none"
            aria-label="Edit Profile"
            onClick={handleIsEdit}
          >
            <PencilIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {/* Profile image */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900"></dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div className=" inline-block">
                {isUploading ? (
                  <div>Carico ...</div>
                ) : (
                  <img
                    className="h-32 w-32 rounded-full border-4 border-white  mx-auto my-4 object-cover aspect-square"
                    src={uploadedUrl || user?.profileImage}
                    alt={user?.firstName}
                  />
                )}
                {isEdit && (
                  <div className=" rounded-full p-1 cursor-pointer">
                    <label htmlFor="file-input" className="cursor-pointer">
                      <ArrowUpOnSquareIcon className="h-6 w-6 text-gray-600" />
                      Carica un'immagine
                    </label>

                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={async (event) => {
                        const file = event.currentTarget.files
                          ? event.currentTarget.files[0]
                          : null;
                        if (file) {
                          const imageUrl = await handleFileUpload(file);
                          if (imageUrl !== null) {
                            setFormData({
                              ...formData,
                              profileImage: imageUrl,
                            });
                          }
                        }
                      }}
                      className="hidden"
                    />
                  </div>
                )}
                {uploadError && (
                  <div className="text-red-800">{uploadError}</div>
                )}
              </div>
            </dd>
          </div>

          {/* First and last name */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Nome</dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {!isEdit && (
                <div className="flex items-center space-x-2">
                  <span>{formData.firstName}</span>
                  <span>{formData.lastName}</span>
                </div>
              )}
              {isEdit && (
                <>
                  <input
                    type="text"
                    value={formData?.firstName}
                    name="firstName"
                    placeholder="Nome"
                    onChange={handleInputChange}
                  />{" "}
                  <input
                    type="text"
                    name="lastName"
                    value={formData?.lastName || ""}
                    placeholder="Cognome"
                    onChange={handleInputChange}
                  />
                </>
              )}
            </dd>
          </div>

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Sesso</dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div className="flex items-center space-x-2">
                <span>
                  {formData.gender} {isEdit && <span>(Non modificabile)</span>}
                </span>
              </div>
            </dd>
          </div>

          {/* Birthday */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">
              Il tuo compleanno
            </dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {isEdit && (
                <input
                  type="date"
                  id="birth-date"
                  name="birthDate"
                  value={formatBirthDate(formData?.birthDate)}
                  onChange={handleInputChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-2xl text-gray-800 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                />
              )}
              {!isEdit &&
                (user?.birthDate != null
                  ? `${new Date(
                      user?.birthDate
                    ).toDateString()} (${calculateAge(
                      user?.birthDate
                    )} anni) - ${getZodiacSign(new Date(user?.birthDate))}`
                  : "Se ci dici quando sei nato ti faremo gli auguri! ;-)")}
            </dd>
          </div>

          {/* Phone */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Cellulare</dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {isEdit && (
                <input
                  type="phone"
                  name="phoneNumber"
                  value={formData.phoneNumber || undefined}
                  onChange={handleInputChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-2xl text-gray-800 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                />
              )}

              {!isEdit &&
                (user?.phoneNumber != null && user?.phoneNumber !== ""
                  ? user?.phoneNumber
                  : "Non hai inserito il tuo numero di telefono")}
            </dd>
          </div>

          {/* Children */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm/6 font-medium text-gray-900">Figliə</dt>
            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {!isEdit &&
                (user?.children == null || user?.children?.length === 0 ? (
                  <span className="text-gray-600 text-sm">
                    Non hai inserito i tuoi figli, se ne hai modifica il tuo
                    profilo
                  </span>
                ) : (
                  <div className="flex justify-center gap-2 px-2">
                    <ChildrenList
                      isEdit={isEdit}
                      childrenList={user?.children || []}
                      refreshProfile={refetch}
                    />
                  </div>
                ))}

              {isEdit && (
                <>
                  <div className="flex justify-center gap-2 px-2">
                    <ChildrenList
                      isEdit={isEdit}
                      childrenList={user?.children || []}
                      refreshProfile={refetch}
                    />
                  </div>
                </>
              )}
            </dd>
          </div>
          <NotificationsArea
            isEdit={isEdit}
            formData={formData}
            setFormData={setFormData}
          />
          {/* Referral */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Codice amico</dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
              {/* Sharing code */}
              <span className="text-gray-700">
                Condividi il tuo referral code per fare entrare nuovi amici nel
                mondo di GioJoy
              </span>
              <div className="mt-5 mb-5 flex items-center border border-gray-300 bg-gray-100  dark:border-gray-700 rounded-md p-2 w-60">
                <span className="text-gray-600  font-semibold text-md flex-1 ">
                  {user?.referralCode}
                </span>
                <DocumentDuplicateIcon
                  className={`cursor-pointer ml-2 w-6 h-6 ${
                    iconClicked
                      ? `text-green-500 ${styles.iconAnimate}`
                      : "text-gray-600 d"
                  }`}
                  onClick={handleCopy}
                />
              </div>
              <div className="w-25">
                <QRNormal
                  icon={uploadedUrl || user?.profileImage}
                  value={baseUrl + "/signup?ic=" + user?.referralCode}
                  size={70}
                  iconScale={0.2}
                />
              </div>
            </dd>
          </div>
          {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">About</dt>
            <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
              Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
              incididunt cillum culpa consequat. Excepteur qui ipsum aliquip
              consequat sint. Sit id mollit nulla mollit nostrud in ea officia
              proident. Irure nostrud pariatur mollit ad adipisicing
              reprehenderit deserunt qui eu.
            </dd>
          </div> */}
        </dl>
      </div>
    </div>
  );
}

function NotificationsArea({
  isEdit,
  formData,
  setFormData,
}: {
  isEdit: boolean;
  formData: UserData;
  setFormData: (value: React.SetStateAction<UserData>) => void;
}) {
  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm/6 font-medium text-gray-900">Notifiche</dt>

      <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
        <div className="mt-3 space-y-3">
          <fieldset>
            <div className="mt-6 space-y-6">
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="new-events-email"
                    disabled={!isEdit}
                    name="new-events-email"
                    type="checkbox"
                    checked={formData.allowNewEventEmails}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        allowNewEventEmails: event.target.checked,
                      });
                    }}
                    className={`size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 ${
                      !isEdit ? "cursor-not-allowed" : ""
                    }`}
                    title={
                      !isEdit
                        ? "Clicca sull'icona della matita per modificare il profilo"
                        : ""
                    }
                  />
                </div>
                <div className="text-sm/6">
                  <label
                    htmlFor="new-events-email"
                    className="font-medium text-gray-900"
                  >
                    Nuovi eventi (email)
                  </label>
                  <p className="text-gray-500">
                    Ricevi una mail ogni volta che viene creato un nuovo evento.
                  </p>
                </div>
              </div>
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="new-events-push"
                    name="new-events-push"
                    type="checkbox"
                    disabled={true}
                    className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 opacity-50 cursor-not-allowed"
                    checked={false}
                  />
                </div>
                <div className="text-sm/6">
                  <label
                    htmlFor="new-events-push"
                    className="font-medium text-gray-900 opacity-50"
                  >
                    Nuovi eventi (push notification)
                  </label>
                  <p className="text-gray-500 opacity-50">
                    Ricevi una notifica push ogni volta che viene creato un
                    nuovo evento.
                  </p>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </dd>
    </div>
  );
}
