import { GET_ME } from "../api/userService";
import { ApolloQueryResult, useQuery } from "@apollo/client";
import { useAuth } from "../../auth/hooks/useAuth";
import { UserModel } from "../models/userModel";

interface UseGetProfileResult {
  user: UserModel | null;
  error: any;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<{ me: UserModel }>>;
}

const useGetProfile = (): UseGetProfileResult => {
  const { isAuthenticated } = useAuth();
  const { data, error, loading, refetch } = useQuery<{ me: UserModel }>(GET_ME, {
    skip: !isAuthenticated, 
    onError: (error) => {
      if (
        error.graphQLErrors.some(
          (err) => err.extensions?.code === "UNAUTHORIZED"
        )
      ) {
        console.error("[GraphQL call] Unauthorized error:", error);
      }
    },
  });

  

  return { user: data?.me || null, error, loading, refetch };
};

export default useGetProfile;
