const CookiePolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 text-gray-800">
      
      <h1 className="text-3xl font-bold text-center mb-8">Cookie Policy</h1>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Premessa</h2>
        <p className="mb-4">
          La presente cookie policy è riferita esclusivamente al sito GioJoy ("Sito") e deve essere intesa come parte integrante della Privacy Policy del medesimo.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Definizioni, caratteristiche e applicazione della normativa</h2>
        <p className="mb-4">
          I cookie sono piccoli file di testo che i siti visitati dall'utente inviano e registrano sul suo computer o dispositivo mobile, per essere poi ritrasmessi agli stessi siti alla successiva visita.
        </p>
        {/* More paragraphs... */}
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Tipologie di cookie utilizzate dal Sito</h2>
        <p className="mb-4">
          Il Sito GioJoy utilizza le seguenti tipologie di cookie:
        </p>
        <ul className="list-disc pl-6 space-y-2 mb-4">
          <li className="text-gray-700">
            <span className="font-medium">Cookie tecnici</span> – di navigazione o sessione – strettamente necessari per il funzionamento del Sito.
          </li>
          <li className="text-gray-700">
            <span className="font-medium">Cookie analytics</span> – che consentono al gestore del Sito di comprendere come questo viene utilizzato dagli utenti.
          </li>
          <li className="text-gray-700">
            <span className="font-medium">Cookie di funzionalità</span> – utilizzati per attivare specifiche funzionalità del Sito.
          </li>
          <li className="text-gray-700">
            <span className="font-medium">Cookie di profilazione</span> – volti a creare profili relativi all'utente.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Cookie di terze parti utilizzati da GioJoy</h2>
        <p className="mb-4">
          GioJoy utilizza cookie di terze parti, tra cui:
        </p>
        <ul className="list-disc pl-6 space-y-2">
          <li>
            <a href="https://www.google.it/intl/it/policies/technologies/types/" 
               className="text-blue-600 hover:underline">
              Google
            </a>
          </li>
          <li>
            <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB"
               className="text-blue-600 hover:underline">
              Google Analytics
            </a>
          </li>
        </ul>
      </section>

      <div className="p-4 bg-yellow-50 border border-yellow-200 rounded-lg">
        <p className="text-yellow-800 font-medium">
          ATTENZIONE: disabilitando i cookie tecnici e/o di funzionalità il Sito potrebbe risultare non consultabile o alcuni servizi potrebbero non funzionare correttamente.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicy;