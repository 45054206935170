import { useEffect } from "react";
import { useUserCache } from "../context/UserCacheContext";
import useGetProfile from "../features/user/hooks/useGetProfile";
import { UserModel } from "../features/user/models/userModel";
import { useAuth } from "../features/auth/hooks/useAuth";

const CACHE_DURATION = 60000; // Cache duration in milliseconds (e.g., 60 seconds)

const useCachedProfile = (): {
  user: UserModel | null;
  loading: boolean;
  error: any;
} => {
  const { user, setUser, lastFetched, setLastFetched } = useUserCache();
  const { loading, error, refetch } = useGetProfile();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      const now = Date.now();
      if (!user || now - lastFetched > CACHE_DURATION) {
        const fetchData = async () => {
          const result = await refetch();
          const fetchedUser = result.data?.me;
          if (fetchedUser) {
            setUser(fetchedUser);
            setLastFetched(now);
          }
        };
        fetchData();
      }
    }
  }, [user, lastFetched, setUser, setLastFetched, refetch, isAuthenticated]);

  return { user, loading, error };
};

export default useCachedProfile;
