import { LIST_EVENTS_QUERY } from "../api/eventService";
import { EventModel } from "../models/eventModel";
import { useQuery } from "@apollo/client";

interface UseEventsParams {
  first: number;
  after?: string | null;
  startDate: Date;
  endDate: Date;
  latitude: number;
  longitude: number;
  radius: number;
}

const formatDateToISODateString = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

export const useEvents = ({
  first,
  after = null,
  startDate,
  endDate,
  latitude,
  longitude,
  radius,
}: UseEventsParams) => {
 
  const { data, loading, error, refetch } = useQuery(LIST_EVENTS_QUERY, {
    variables: {
      first,
      after,
      startDate: formatDateToISODateString(startDate),
      endDate: formatDateToISODateString(endDate),
      latitude,
      longitude,
      radius,
    },
    fetchPolicy: "cache-and-network", // Adjust based on your needs
  });


 
  const events: EventModel[] = data
    ? data.events.edges.map((edge: { node: EventModel }) => ({
        ...edge.node,
        date: new Date(edge.node.date),
      }))
    : [];

  return { events, loading, error, refetch };
};
