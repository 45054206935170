import { APP_NAME, LOGO_URL } from "../consts";

export default function Logo() {
  return (
    <div className="flex flex-shrink-0 items-center">
      <img
        alt="GioJoy Logo"
        src={LOGO_URL}
        className="h-8 w-auto mr-2" 
      />
      <span className="mr-2">{APP_NAME}</span>
    </div>
  );
}
