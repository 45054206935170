import { useQuery } from "@apollo/client";
import {  useParams } from "react-router-dom";
import { GET_EVENT_QUERY } from "../api/eventService";
import EventCard from "../components/EventCard";
import Loading from "../../../components/Loading";
import ErrorPanel from "../../../components/ErrorPanel";

export default function Event() {
  const { eventId } = useParams<{ eventId: string }>();
  
  const { data, error, loading, refetch } = useQuery(GET_EVENT_QUERY, {
    variables: { id: eventId },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPanel message={error.message} />;
  }

  return (
    <div className="w-full">
      <EventCard event={data?.event} refetch={refetch} />
    </div>
  );
}
