import {
  EventModel,
  getAgeRangeHumanReadable,
} from "../models/eventModel";
import { MapIcon } from "@heroicons/react/24/outline";
import { UserIcon, KidIcon, UserGroupIcon } from "hugeicons-react";

export default function EventProperties({ event }: { event: EventModel }) {
  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {event.allowKids ? (
          <li key={event.id}>
            <div className="relative pb-4">
              <div className="relative flex space-x-3">
                <div>
                  <span className="bg-green-500 flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white">
                    <KidIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-white"
                    />
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm font-medium text-gray-900">
                      Con o senza figliə
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    {event.kidsAgeRange?.min === 0 &&
                    event.kidsAgeRange?.max === 18
                      ? "tutte le età ammesse"
                      : `età da ${event.kidsAgeRange?.min} - ${event.kidsAgeRange?.max} anni`}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ) : (
          <li key={event.id}>
            <div className="relative pb-4">
              <div className="relative flex space-x-3">
                <div>
                  <span className="bg-red-500 flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white">
                    <UserIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-white"
                    />
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm font-medium text-gray-900">
                      Per adulti
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500">
                    {getAgeRangeHumanReadable(event.ageRange)}
                  </div>
                </div>
              </div>
            </div>
          </li>
        )}
        <li>
          <div className="relative pb-4">
            <div className="relative flex space-x-3">
              <div>
                <span className="bg-yellow-300 flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white">
                  <UserGroupIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-white"
                  />
                </span>
              </div>
              <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    Posti disponibili
                  </p>
                </div>
                <div className="whitespace-nowrap text-right text-sm text-gray-500">
                  {event.maxSubscribers > 0
                    ? `massimo ${event.maxSubscribers}`
                    : "nessun limite"}
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="relative pb-4">
            <div className="relative flex space-x-3">
              <div>
                <span className="bg-blue-500 flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white">
                  <MapIcon aria-hidden="true" className="h-5 w-5 text-white" />
                </span>
              </div>
              <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                  <p className="text-sm font-medium text-gray-900">Dove</p>
                </div>
                <div className="max-w-[200px] break-words text-right text-sm text-gray-500">
                  {event.location.description}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
