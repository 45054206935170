import { DayVM } from "../viewModels/dayVM";



export const generateDaysForMonth = (
  currentMonth: number,
  currentYear: number
): DayVM[] => {
  const days: DayVM[] = [];
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const today = new Date();
  
  // Find the closest Monday to the first day of the month
  let startDate = new Date(firstDayOfMonth);
  while (startDate.getDay() !== 1) {
    startDate.setDate(startDate.getDate() - 1);
  }

  // Generate 42 DayVM objects
  for (let i = 0; i < 42; i++) {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + i);

    const isCurrentMonth = date.getMonth() === currentMonth;
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    const dayVM: DayVM = {
      id: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      date: date,
      isCurrentMonth: isCurrentMonth,
      isToday: isToday,
      events: [] // Assuming no events for now
    };

    days.push(dayVM);
  }

  return days;
};

  export const getCurrentMonthRange = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  
    return {
      startDate:start,
      endDate: end,
    };
  };

    /**
   * Returns the start and end dates of a given month and year.
   * @param {number} month - The month (1-12).
   * @param {number} year - The year (e.g., 2023).
   * @returns {{ startDate: Date, endDate: Date }} - The start and end dates of the month.
   */
  export function getMonthStartAndEndDates(month: number, year: number): { startDate: Date, endDate: Date } {
    // Ensure the month is within the valid range
    if (month < 1 || month > 12) {
      throw new Error("Month must be between 1 and 12");
    }
  
    // Create the start date
    const startDate = new Date(year, month - 1, 1);
  
    // Create the end date
    const endDate = new Date(year, month, 0); // The 0th day of the next month is the last day of the given month
  
    return { startDate, endDate };
  }
  
  