import { useState } from "react";

const useFileUpload = (id: string, uploadFn: (id: string, file: File) => Promise<string> ) => {
    const [uploadedUrl, setUploadedUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
  
    const handleFileUpload = async (file: File): Promise<string | null> => {
      setIsUploading(true);
      setError(null);
  
      try {
        const url = await uploadFn(id, file);
        setUploadedUrl(url);
        return url;
      } catch (err) {
        setError('Failed to upload file');
        return null;
      } finally {
        setIsUploading(false);
      }
    };
  
    return {
      uploadedUrl,
      error,
      isUploading,
      handleFileUpload,
    };
  };
  
  export default useFileUpload;