import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  RssIcon,
} from "@heroicons/react/24/outline";
import { EventFilters } from "../hooks/useFilteredEvents";
import { CREATE_EVENT_ROUTE } from "../../../routes";
import { cities } from "../../../consts";
import { Link } from "react-router-dom";
import config from "../../../config/config";
import { filtersVar } from "../api/eventsFilter";

interface EventsFilterHeaderProps {
  filters: EventFilters;
}

const EventsFilterHeader: React.FC<EventsFilterHeaderProps> = ({ filters }) => {
  const today = new Date();

  const monthName = (month: number, year: number) =>
    new Intl.DateTimeFormat(navigator.language, {
      month: "long",
    }).format(new Date(year, month));

  const handlePreviousMonth = () => {
    let localCurrentMonth = filters.month;
    let localCurrentYear = filters.year;
    if (filters.month === 0) {
      localCurrentMonth = 11;
      localCurrentYear = localCurrentYear - 1;
    } else {
      localCurrentMonth = localCurrentMonth - 1;
    }

    filtersVar({
      ...filtersVar(),
      month: localCurrentMonth,
      year: localCurrentYear,
    });
  };

  const abbreviatedMonthName = (month: number, year: number) =>
    new Intl.DateTimeFormat(navigator.language, {
      month: "short",
    }).format(new Date(year, month));

  const handleNextMonth = () => {
    let localCurrentMonth = filters.month;
    let localCurrentYear = filters.year;
    if (filters.month === 11) {
      localCurrentMonth = 0;
      localCurrentYear = localCurrentYear + 1;
    } else {
      localCurrentMonth = localCurrentMonth + 1;
    }

    filtersVar({
      ...filtersVar(),
      month: localCurrentMonth,
      year: localCurrentYear,
    });
  };

  const handleTodayClick = () => {
    filtersVar({
      ...filtersVar(),
      month: today.getMonth(),
      year: today.getFullYear(),
    });
  };

  function capitalizeFirstChar(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return (
    <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
      <h1 className="flex items-center space-x-2 text-xl font-semibold leading-6 text-gray-900">
        <time dateTime="2022-01">
          <span className="hidden sm:inline">
            {capitalizeFirstChar(monthName(filters.month, filters.year))}{" "}
            {filters.year}
          </span>
          <span className="sm:hidden">
            {capitalizeFirstChar(
              abbreviatedMonthName(filters.month, filters.year)
            )}{" "}
            {filters.year.toString().slice(-2)}
          </span>
        </time>
        <Link
          to={`${config.api.rest}/api/calendar/torino.ics`}
          className="flex  items-center justify-center p-2 bg-blue-100 rounded-md hover:bg-blue-300"
          title="Scarica il calendario in formato ICS"
        >
          <RssIcon className="h-4 w-4" aria-hidden="true" />
        </Link>
      </h1>

      <div className="ml-3 flex items-center ">
        <div className="relative flex items-center  bg-white md:items-stretch">
          <div className="flex md:items-center justify-center">
            {cities[0].name}
          </div>
        </div>

        <div className="ml-6 h-6 w-px"></div>

        <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
          <button
            type="button"
            className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
            onClick={handlePreviousMonth}
          >
            <span className="sr-only">Mese precedente</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
            onClick={handleTodayClick}
          >
            Oggi
          </button>
          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button
            type="button"
            className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
            onClick={handleNextMonth}
          >
            <span className="sr-only">Mese successivo</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden md:ml-4 md:flex md:items-center">
          <div className="ml-6 h-6 w-px bg-gray-300" />
          <a
            type="button"
            className="ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            href={CREATE_EVENT_ROUTE}
          >
            Crea evento
          </a>
        </div>
        <Menu as="div" className="relative ml-6 md:hidden">
          <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500 bg-gray-200 hover:bg-gray-300">
            <span className="sr-only">Open menu</span>
            <EllipsisHorizontalIcon className="h-6 w-6" aria-hidden="true" />
          </MenuButton>

          <MenuItems
            transition
            className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="py-1">
              <MenuItem>
                <a
                  href={CREATE_EVENT_ROUTE}
                  className="block px-4 py-2 text-sm font-semibold text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                  Crea evento
                </a>
              </MenuItem>
            </div>
            <div className="py-1">
              <MenuItem>
                <button
                  className="block px-4 py-2 text-sm font-semibold text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  onClick={handleTodayClick}
                >
                  Oggi
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </div>
    </header>
  );
};

export default EventsFilterHeader;
