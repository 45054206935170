import { SvgIcon } from "./SvgIcon";

export function EmptyMyEventsIcon() {
  return (
    <SvgIcon width={300} height={300} viewBox="0 0 200 200">
      <rect width="200" height="200" fill="#f3f4f6" rx="20" />
      <rect
        x="30"
        y="50"
        width="140"
        height="120"
        rx="10"
        fill="#ffffff"
        stroke="#d1d5db"
        stroke-width="2"
      />
       <line
        x1="30"
        y1="70"
        x2="170"
        y2="70"
        stroke="#d1d5db"
        stroke-width="2"
      />
      <text
        x="100"
        y="100"
        fill="#9ca3af"
        font-family="Arial, sans-serif"
        font-size="14"
        text-anchor="middle"
      ></text>
      <path
        d="M70 140 C80 130, 120 130, 130 140"
        fill="none"
        stroke="#f87171"
        stroke-width="3"
      />
      <ellipse cx="85" cy="115" rx="6" ry="8" fill="#f87171" />
      <circle cx="85" cy="114" r="2" fill="#ffffff" />
      <ellipse cx="115" cy="115" rx="6" ry="8" fill="#f87171" />
      <circle cx="115" cy="114" r="2" fill="#ffffff" />
      <path
        d="M78 105 Q85 100 92 105"
        stroke="#f87171"
        stroke-width="2"
        fill="none"
      />
      <path
        d="M108 105 Q115 100 122 105"
        stroke="#f87171"
        stroke-width="2"
        fill="none"
      />
    </SvgIcon>
  );
}
