import React, { useCallback } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  title: string | null;
  disabled?: boolean;
  buttonLabel?: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onCancel,
  title,
  disabled = false,
  buttonLabel = "Salva",
  children,
}) => {
  const handleCancel = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onCancel();
    },
    [onCancel]
  );

  const handleBackdropClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.target === e.currentTarget) {
        onCancel();
      }
    },
    [onCancel]
  );
  
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleBackdropClick}
    >
      <div className="relative bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <button
          className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-900"
          onClick={handleCancel}
        >
          &times;
        </button>

        <h1 className="text-xl mb-3">{title || ""}</h1>
        {children}

        {buttonLabel !== "" && (
          <button
            className="bg-indigo-600 text-white px-3 py-2 rounded-md mt-4"
            onClick={onClose}
            disabled={disabled}
          >
            {buttonLabel}
          </button>
        )}
      </div>
    </div>
  );
};

export default Modal;
