import { useState } from "react";
import { createEvent } from "../api/eventService";
import { CreateEventInput } from "../models/eventModel";

export const useCreateEvent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [eventId, setEventId] = useState<string | null>(null);

  const handleCreateEvent = async (input: CreateEventInput) => {
    setLoading(true);
    setError(null);
    try {
      const id = await createEvent(input);
      setEventId(id);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    eventId,
    handleCreateEvent,
  };
};