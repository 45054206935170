import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import App from "./App";
import { UserCacheProvider } from "./context/UserCacheContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import graphQLClient from "./services/gqlClients";
import * as Sentry from "@sentry/react";

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const tagManagerArgs = {
  gtmId: "GTM-N9Q5LS54",
};

TagManager.initialize(tagManagerArgs);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://3c0b539ca39664e7dba45281bde8d3a7@o348659.ingest.us.sentry.io/4508414264082432",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

root.render(
  <React.StrictMode>
    <ApolloProvider client={graphQLClient}>
      <UserCacheProvider>
        <App />
      </UserCacheProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
