import {  CalendarIcon} from "@heroicons/react/24/outline";
import { CREATE_EVENT_ROUTE } from "../../../routes";

const EmptyEventsList = () => {
  return (
    <div className="text-center">
     <img src={`${process.env.PUBLIC_URL}/sad_calendar.png`}  className="mx-auto" alt="nessun evento" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">Ancora nessun evento per il mese</h3>
      <p className="mt-1 text-sm text-gray-500">Fai la prima mossa, creane uno!</p>
      <div className="mt-6">
        <a
          type="button"
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          href={CREATE_EVENT_ROUTE}
        >
          <CalendarIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
          Crea evento
        </a>
      </div>
    </div>
  );
};

export default EmptyEventsList;
