const people = [
  {
    name: "Leslie Alexander",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    amount: 100,
  },
  {
    name: "Michael Foster",
    imageUrl:
      "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    amount: 50,
  },
  {
    name: "Dries Vincent",
    email: "dries.vincent@example.com",
    imageUrl:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    amount: 200,
  },
  {
    name: "Lindsay Walton",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    amount: 5,
  },
  {
    name: "Courtney Henry",
    imageUrl:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    amount: 100,
  },
  {
    name: "Tom Cook",
    imageUrl:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    amount: 10,
  },
];

export interface Donor {
    name: string;
    imageUrl: string;
    amount: number;
}

export default function DonorsList() {
  return (
    <div className=" max-w-lg mx-auto overflow-auto">
      <h1 className="text-3xl">Ultime donazioni (12.000 € raccolti)</h1>
      <ul className="divide-y divide-gray-100">
        {people.map((person) => (
          <li key={person.email} className="flex gap-x-4 py-5">
            <img
              alt=""
              src={person.imageUrl}
              className="size-12 flex-none rounded-full bg-gray-50"
            />
            <div className="min-w-0">
              <p className="text-sm/6 font-semibold text-gray-900">
                {person.name}
              </p>
            </div>
            <span className="ml-auto w-20 text-lg font-bold text-black bg-gray-50 px-2 py-1 rounded text-right">
              {person.amount} €
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
