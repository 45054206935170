// FileInput.tsx
import React, { useRef } from "react";
import { useFormik } from "formik";

interface ImageInputProps {
  formik: ReturnType<typeof useFormik>;
  disabled: boolean;
  MAX_FILE_SIZE: number;
  handleFileUpload: (file: File) => Promise<string | null>;
  uploadedUrl: string | null;
  buttonLabel?: string;
}

const ImageInput: React.FC<ImageInputProps> = ({
  formik,
  disabled,
  MAX_FILE_SIZE,
  handleFileUpload,
  uploadedUrl,
  buttonLabel,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        formik.setFieldError(
          "fileUpload",
          `La dimensione del file supera i ${MAX_FILE_SIZE / (1024 * 1024)}MB.`
        );
        return;
      }
      await handleFileUpload(file);
      formik.setFieldValue("fileUpload", file);
      formik.setFieldValue("images", [uploadedUrl]);
    }
  };
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="mt-1">
      <input
        id="file-upload"
        name="fileUpload"
        ref={fileInputRef}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        type="file"
        className="sr-only"
        disabled={disabled}
        accept="image/*"
      />
      <button
        type="button"
        onClick={handleClick}
        className={`px-4 py-2 rounded-md text-white ${
          disabled
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-blue-500 hover:bg-blue-600"
        }`}
        disabled={disabled}
      >
        {buttonLabel || "Carica immagine"}
      </button>
    </div>
  );
};

export default ImageInput;
