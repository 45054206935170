import axios from 'axios';
import { ACCESS_TOKEN_KEY } from '../consts';
import config from '../config/config';

const restClient = axios.create({
  baseURL: config.api.rest,
  headers: {
    'Content-Type': 'application/json',
  },
});

restClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY) || sessionStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

restClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) { // TODO: Check if this is the right way to hanlde it (in the GraphQL client it wasn't like this)
      console.error('[REST call] Unauthorized error:', error);
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default restClient;