import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client";
import { onError } from '@apollo/client/link/error';

import { setContext } from "@apollo/client/link/context";

import config from "../config/config";
import { ACCESS_TOKEN_KEY } from "../consts";



const errorLink = onError(({ graphQLErrors, networkError }) => {

  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions?.code === 'UNAUTHORIZED') {
        console.error('[GraphQL call] Unauthorized error:', err);
      }
    }
  }

  if (networkError) {
    console.error('Network error:', networkError);
  }
});

const httpLink = createHttpLink({
  uri: config.api.graphql,
});


const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY) || sessionStorage.getItem(ACCESS_TOKEN_KEY);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Event: {
      keyFields: ["id"],
    },
    EventSubscriber: {
      keyFields: ["id"],
    },
    // You can add more type policies for other types if necessary
  },
});

const graphQLClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: cache,
});

export default graphQLClient;