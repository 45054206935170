import { gql } from "@apollo/client";

export const AUTHENTICATE_MUTATION = gql`
  mutation Authenticate($username: String!, $password: String!) {
    authenticate(username: $username, password: $password) {
      accessToken
      refreshToken
      tokenType
      expiresIn
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser (
      $name: String!
      $lastname: String!
      $username: String!
      $password: String!
      $invitationCode: String!
      $birthDate: Time!
      $phoneNumber: String!
      $gender: Gender!
    ) {
        createUser(
          input: {
            username: $username
            password: $password
            name: $name
            lastname: $lastname
            invitationCode: $invitationCode
            birthDate: $birthDate
            phoneNumber: $phoneNumber
            gender: $gender         
          }
        )
      }

`;


export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;


export const RESET_PASSWORD_MUTATION = gql`
  mutation PasswordReset($token: String!, $password: String!) {
      passwordReset(token: $token, password: $password)
  }
`;


export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      tokenType
      expiresIn
    }
  }
`;