import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8 text-gray-800">
      <h1 className="text-3xl font-bold text-center mb-8">Privacy Policy di GioJoy</h1>
      <p className="text-sm text-gray-600 text-center mb-8">
        Ultimo aggiornamento: 23 novembre 2024
      </p>

      <ol className="list-decimal list-outside space-y-6">
        <li>
          <h2 className="text-xl font-semibold mb-4">Titolare del trattamento</h2>
          <p>
            Il Titolare del trattamento dei dati personali relativi all’utilizzo del sito GioJoy è{" "}
            <strong>LITTLE ENDIAN DI GUGLIELMINO FABRIZIO GIUSEPPE</strong>, con sede
            legale in Via Mombello, 1A - 10044 PIANEZZA (TO), contattabile tramite email:{" "}
            <a
              href="mailto:info@little-endian.it"
              className="text-blue-600 hover:underline"
            >
              info@little-endian.it
            </a>
            .
          </p>
        </li>

        <li>
          <h2 className="text-xl font-semibold mb-4">Tipologie di dati personali trattati</h2>
          <ol className="list-disc list-inside pl-4 space-y-4">
            <li>
              <strong>Dati di navigazione:</strong> raccolti automaticamente durante la
              navigazione (ad esempio indirizzi IP, URI delle risorse richieste, orari di accesso)
              per scopi statistici e di sicurezza.
            </li>
            <li>
              <strong>Dati particolari:</strong> potrebbero includere informazioni su convinzioni
              filosofiche, culturali o altri interessi legati agli eventi organizzati. Questi dati
              saranno trattati solo con consenso esplicito.
            </li>
            <li>
              <strong>Dati forniti volontariamente dagli utenti:</strong> informazioni fornite dagli
              utenti attraverso il sito, come recensioni o commenti.
            </li>
            <li>
              <strong>Cookie:</strong> Per maggiori informazioni consulta la{" "}
              <a
                href="/cookie-policy"
                className="text-blue-600 hover:underline"
              >
                Cookie Policy di GioJoy
              </a>
              .
            </li>
          </ol>
        </li>

        <li>
          <h2 className="text-xl font-semibold mb-4">Finalità del trattamento</h2>
          <p>I dati personali raccolti saranno trattati per:</p>
          <ul className="list-disc list-inside pl-4 space-y-2">
            <li>Fornire i servizi richiesti, inclusa l’organizzazione e la promozione di eventi.</li>
            <li>Rispondere a richieste di supporto o informazioni.</li>
            <li>Facilitare le attività degli <strong>Organizzatori Eventi</strong>.</li>
            <li>
              Personalizzare l’esperienza dell’utente e proporre eventi in linea con i suoi
              interessi.
            </li>
            <li>Comunicare dati ai partner autorizzati per finalità commerciali.</li>
            <li>Adempiere a obblighi legali, contabili o fiscali.</li>
            <li>Inviare comunicazioni come newsletter o aggiornamenti sui servizi.</li>
          </ul>
        </li>

        <li>
          <h2 className="text-xl font-semibold mb-4">Base legale del trattamento</h2>
          <p>
            Il trattamento dei dati personali si basa sulle seguenti basi giuridiche:
          </p>
          <ul className="list-disc list-inside pl-4 space-y-2">
            <li>
              <strong>Esecuzione di un contratto:</strong> per finalità legate alla fornitura dei
              servizi richiesti.
            </li>
            <li>
              <strong>Consenso:</strong> per attività di profilazione e comunicazione a partner
              commerciali.
            </li>
            <li>
              <strong>Obblighi legali:</strong> per l’adempimento di obblighi normativi.
            </li>
            <li>
              <strong>Interesse legittimo:</strong> per garantire il funzionamento e la sicurezza
              del sito.
            </li>
          </ul>
        </li>

        <li>
          <h2 className="text-xl font-semibold mb-4">Destinatari dei dati personali</h2>
          <p>
            I dati personali potranno essere comunicati a fornitori di servizi tecnologici,
            consulenti professionali, partner commerciali autorizzati e autorità competenti.
          </p>
        </li>

        <li>
          <h2 className="text-xl font-semibold mb-4">Trasferimento dei dati personali</h2>
          <p>
            I dati personali raccolti da GioJoy non saranno trasferiti al di fuori dello Spazio
            Economico Europeo.
          </p>
        </li>

        <li>
          <h2 className="text-xl font-semibold mb-4">Periodo di conservazione</h2>
          <p>
            I dati personali saranno conservati per il tempo necessario a soddisfare le finalità
            indicate, rispettando il principio di minimizzazione.
          </p>
        </li>

        <li>
          <h2 className="text-xl font-semibold mb-4">Diritti degli interessati</h2>
          <p>
            Gli utenti hanno il diritto di accedere, rettificare, cancellare, limitare o opporsi al
            trattamento dei propri dati personali. Per esercitare i propri diritti, è possibile
            inviare una richiesta a{" "}
            <a
              href="mailto:info@little-endian.it"
              className="text-blue-600 hover:underline"
            >
              info@little-endian.it
            </a>
            .
          </p>
        </li>

        <li>
          <h2 className="text-xl font-semibold mb-4">Modifiche alla privacy policy</h2>
          <p>
            Questa privacy policy è soggetta a modifiche e aggiornamenti. Eventuali variazioni
            saranno comunicate attraverso il sito e saranno immediatamente vincolanti.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;