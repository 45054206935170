import React, { useEffect, useMemo, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import { CreateEventInput, UpdateEventInput } from "../models/eventModel";
import { useCreateEvent } from "../hooks/useCreateEvent";
import Loading from "../../../components/Loading";
import { PhotoIcon } from "@heroicons/react/24/outline";
import AddressAutocomplete from "../../../components/AddressAutocomplete";
import { generateSlug } from "../../../utility/slugify";
import { useNavigate, useParams } from "react-router-dom";
import useGetProfile from "../../user/hooks/useGetProfile";
import useFileUpload from "../../../hooks/useFileUpload";
import {
  GET_EVENT_QUERY,
  UPDATE_EVENT_MUTATION,
  uploadEventFile,
} from "../api/eventService";
import ErrorPanel from "../../../components/ErrorPanel";
import { useMutation, useQuery } from "@apollo/client";
import ImageInput from "../components/ImageInput";
import DateTimeInput from "../../../components/DateTimeInput";

const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const validationSchema = Yup.object({
  title: Yup.string().required("Serve il titolo dell'evento"),
  description: Yup.string().required("La descrizione è obbligatoria"),
  date: Yup.date()
    .required("La data è obbligatoria")
    .test(
      "not-today",
      "La data non può essere oggi",
      (value) => !isToday(value)
    ),
  endDate: Yup.date()
    .nullable()
    .when("date", (date, schema) =>
      date
        ? schema.min(
            date,
            "La data di fine deve essere successiva alla data di inizio"
          )
        : schema
    ),
  ageRange: Yup.object({
    min: Yup.number()
      .required("Inserisci l'età minima")
      .min(18, "Indicare il range di età per l'evento"),
    max: Yup.number()
      .required("L'età massima è obbligatoria")
      .max(99, "Indicare il range di età per l'evento"),
  }).required(),
  allowKids: Yup.boolean(),
  kidsAgeRange: Yup.object({
    min: Yup.number().when("$allowKids", {
      is: true,
      then: (schema) =>
        schema
          .required("Età minima dei figli richiesta")
          .typeError("Seleziona un'età minima valida"),
      otherwise: (schema) => schema.nullable(),
    }),
    max: Yup.number().when("$allowKids", {
      is: true,
      then: (schema) =>
        schema
          .required("Età massima dei figli richiesta")
          .typeError("Seleziona un'età massima valida"),
      otherwise: (schema) => schema.nullable(),
    }),
  }).default({ min: null, max: null }),
  images: Yup.array()
    .of(Yup.string())
    .min(1, "Devi caricare almeno un'immagine di copertina"),
  // fileUpload: Yup.mixed().required("Devi caricare un'immagine di copertina"),
  price: Yup.object({
    amount: Yup.number().required("Il prezzo è obbligatorio"),
    currency: Yup.string().required("La valuta è obbligatoria"),
  }).required(),
  location: Yup.object({
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    name: Yup.string().required("Nome del luogo richiesto"),
    description: Yup.string().required("Descrizione evento richiesta"),
  }).required(),
});

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

export default function NewEvent() {
  const { eventId: routeEventId } = useParams<{ eventId: string }>(); // Renamed to avoid conflict
  const isEditMode = Boolean(routeEventId);

  const {
    loading: loadingCreate,
    handleCreateEvent,
    eventId: createdEventId,
  } = useCreateEvent();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  //
  const [updateEvent, { loading: loadingUpdate, error: updateError }] =
    useMutation(UPDATE_EVENT_MUTATION, {
      onCompleted: () => setUpdateSuccess(true),
    });

  const { user } = useGetProfile();
  const navigate = useNavigate();

  const {
    data: fetchedData,
    loading: loadingFetch,
    error: fetchError,
  } = useQuery(GET_EVENT_QUERY, {
    variables: { id: routeEventId },
    skip: !isEditMode,
    fetchPolicy: "network-only",
  });

  const initialValues = useMemo(() => {
    if (isEditMode && fetchedData) {
      return {
        id: fetchedData.event.id || null,
        title: fetchedData.event.title || "",
        description: fetchedData.event.description || "",
        images: fetchedData.event.images || [],
        date: fetchedData.event.date
          ? new Date(fetchedData.event.date)
          : new Date(),
        endDate: fetchedData.event.endDate
          ? new Date(fetchedData.event.endDate)
          : null,
        ageRange: fetchedData.event.ageRange || { min: 18, max: 99 },
        allowKids: fetchedData.event.allowKids || false,
        kidsAgeRange: fetchedData.event.kidsAgeRange || { min: 0, max: 0 },
        price: fetchedData.event.price || { amount: 0, currency: "EUR" },
        kidPrice: fetchedData.event.kidPrice || { amount: 0, currency: "EUR" },
        location: fetchedData.event.location || {
          latitude: 0.0,
          longitude: 0.0,
          name: "",
          description: "",
        },
        tags: fetchedData.event.tags || [],
        maxSubscribers: fetchedData.event.maxSubscribers || 0,
      };
    } else {
      return {
        id: null,
        title: "",
        description: "",
        images: [],
        date: new Date(),
        endDate: null,
        ageRange: { min: 18, max: 99 },
        allowKids: false,
        kidsAgeRange: { min: null, max: null },
        price: { amount: 0, currency: "EUR" },
        location: {
          latitude: 0.0,
          longitude: 0.0,
          name: "",
          description: "",
        },
        tags: [],
        maxSubscribers: 0,
      };
    }
  }, [isEditMode, fetchedData]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true, // Allow form to reinitialize when fetchedEvent changes
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};
      return errors;
    },
    onSubmit: async (values) => {
      if (isEditMode && routeEventId) {
        // Construct UpdateEventInput with the required id
        const updateInput: UpdateEventInput = {
          id: routeEventId,
          title: values.title,
          description: values.description,
          images: values.images,
          date: values.date,
          endDate: values.endDate,
          price: values.price,
          kidPrice: values.kidPrice,
          location: {
            latitude: values.location.latitude,
            longitude: values.location.longitude,
            name: values.location.name,
            description: values.location.description,
          },
          allowKids: values.allowKids,
          ageRange: {
            min: values.ageRange.min,
            max: values.ageRange.max,
          },
          kidsAgeRange: values.allowKids
            ? {
                min: values.kidsAgeRange?.min || 0,
                max: values.kidsAgeRange?.max || 0,
              }
            : undefined,
          tags: values.tags,
          maxSubscribers: values.maxSubscribers,
        };

        try {
          await updateEvent({
            variables: { eventId: routeEventId, input: updateInput },
          });
          navigate(`/events/`);
        } catch (error) {
          console.error("Error updating event:", error);
          // Optionally, handle the error (e.g., display a notification)
        }
      } else {
        // Construct CreateEventInput without the id
        const createInput: CreateEventInput = {
          title: values.title,
          description: values.description,
          images: values.images,
          date: values.date,
          endDate: values.endDate,
          price: values.price,
          kidPrice: {
            currency: "EUR",
            amount: 0,
          }, // TODO: Handle Kid Price
          location: {
            latitude: values.location.latitude,
            longitude: values.location.longitude,
            name: values.location.name,
            description: values.location.description,
          },
          allowKids: values.allowKids,
          ageRange: {
            min: values.ageRange.min,
            max: values.ageRange.max,
          },
          kidsAgeRange: values.allowKids
            ? {
                min: values.kidsAgeRange?.min || 0,
                max: values.kidsAgeRange?.max || 0,
              }
            : undefined,
          tags: values.tags,
          maxSubscribers: values.maxSubscribers,
        };

        try {
          await handleCreateEvent(createInput);
          navigate("/events");
        } catch (error) {
          console.error("Error creating event:", error);
          // Optionally, handle the error (e.g., display a notification)
        }
      }
    },
  });

  useEffect(() => {
    if (formik.submitCount > 0 && Object.keys(formik.errors).length > 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [formik.submitCount, formik.errors]);

  useEffect(() => {
    // Only redirect after explicit creation or update success
    if (createdEventId || (isEditMode && updateSuccess)) {
      navigate("/events");
    }
  }, [createdEventId, isEditMode, updateSuccess, navigate]);

  if (loadingCreate || loadingUpdate || loadingFetch) {
    return <Loading />;
  }

  if (fetchError || updateError) {
    return <ErrorPanel />;
  }

  const eventName = formik.values.title;
  const eventSlug = generateSlug(eventName);
  const isEventNameFilled = eventName.trim() !== "";

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        <div className="px-4 sm:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Dati del nuovo evento
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Inserisci tutte le informazioni necessarie a creare il nuovo evento.
          </p>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        >
          <div className="px-4 py-6 sm:p-8">
            {formik.submitCount > 0 &&
              Object.keys(formik.errors).length > 0 && (
                <ErrorSummary errors={formik.errors} />
              )}
            <EventName formik={formik} />
            <EventLocation formik={formik} />
            <EventDate formik={formik} />
            {user?.permissions?.canCreatePaidEvent && (
              <EventPrice formik={formik} />

              // TODO: Kid Event Price
            )}

            <EventDescription formik={formik} />

            <EventImageUpload
              formik={formik}
              eventSlug={eventSlug}
              disabled={!isEventNameFilled}
            />

            <MaxEventSubscribers formik={formik} />
            <AgeRange formik={formik} />

            <EventsKids formik={formik} />
          </div>

          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <a
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
              href="/events"
            >
              Annulla
            </a>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Salva
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const ErrorSummary = ({ errors }: { errors: any }) => {
  const errorMessages = Object.keys(errors)
    .map((key) => {
      const error = errors[key];
      if (typeof error === "string") {
        return error;
      } else if (typeof error === "object") {
        return Object.values(error)
          .map((subError) => subError)
          .join(", ");
      }
      return null;
    })
    .filter(Boolean);

  return (
    <div
      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
      role="alert"
    >
      <strong className="font-bold">Errori:</strong>
      <ul className="mt-2">
        {errorMessages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </div>
  );
};

function EventName({ formik }: { formik: any }) {
  return (
    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="sm:col-span-4">
        <label
          htmlFor="website"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Nome dell'evento
        </label>
        <div className="mt-2">
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
            <input
              id="event-title"
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              type="text"
              placeholder="Super Party "
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
          {formik.touched.title && formik.errors.title ? (
            <div className="text-red-600">{formik.errors.title}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function EventLocation({ formik }: { formik: any }) {
  const handleLocationSelect = (
    latitude: number,
    longitude: number,
    name: string
  ) => {
    formik.setFieldValue("location.latitude", latitude);
    formik.setFieldValue("location.longitude", longitude);
    formik.setFieldValue("location.name", name);
    formik.setFieldValue("location.description", name);
  };

  return (
    <div className="col-span-full mt-3">
      <label
        htmlFor="Location"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Luogo dell'evento
      </label>
      <div className="mt-2 ">
        <AddressAutocomplete
          onSelect={handleLocationSelect}
          value={formik.values.location.description}
        />
      </div>
    </div>
  );
}

function EventDate({ formik }: { formik: any }) {
  const handleDateChange = (
    startDateTime: Date | null,
    endDateTime: Date | null
  ) => {
    formik.setFieldValue("date", startDateTime);
    formik.setFieldValue("endDate", endDateTime);
  };
  return (
    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="col-span-1 sm:col-span-6 mt-3 ">
        <label
          htmlFor="Date"
          className="block text-sm font-medium leading-6 text-gray-900 "
        >
          Data dell'evento
        </label>{" "}
        <DateTimeInput
          initialStartDateTime={formik.values.date}
          initialEndDateTime={null}
          onDateTimeChange={handleDateChange}
          showEndTime={true}
          disablePastDates={true}
          startTimeLabel="Ora inizio"
          endTimeLabel="Ora fine (opzionale)"
          dateLabel="Data"
        />
        {formik.touched.date && formik.errors.date ? (
          <div className="text-red-600">{formik.errors.date}</div>
        ) : null}
      </div>
    </div>
  );
}

function EventPrice({ formik }: { formik: any }) {
  const [isFree, setIsFree] = useState(formik.values.price.amount === 0);

  const handleFreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsFree(checked);

    if (checked) {
      formik.setFieldValue("price.amount", 0);
    } else {
      formik.setFieldValue("price.amount", "");
    }
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
    formik.setFieldValue(
      "price.amount",
      isNaN(numericValue) ? "" : numericValue
    );
  };

  const formatCurrency = (value: number) => {
    return (value / 100).toLocaleString("it-IT", {
      style: "currency",
      currency: "EUR",
    });
  };

  return (
    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="sm:col-span-4">
        <label
          htmlFor="event-price"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Costo
        </label>
        <div className="mt-2 flex items-center">
          <input
            id="is-free"
            name="isFree"
            type="checkbox"
            onChange={handleFreeChange}
            checked={isFree}
            className="mr-2"
          />
          <label htmlFor="is-free" className="text-sm text-gray-900 mr-4">
            Free
          </label>
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
            <input
              id="event-price"
              name="price.amount"
              onChange={handlePriceChange}
              onBlur={formik.handleBlur}
              value={isFree ? "" : formatCurrency(formik.values.price.amount)}
              type="text"
              placeholder="0"
              disabled={isFree}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
            {formik.touched.price?.amount && formik.errors.price?.amount ? (
              <div>{formik.errors.price.amount}</div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

function EventDescription({ formik }: { formik: any }) {
  return (
    <div className="col-span-full mt-3">
      <label
        htmlFor="about"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Descrizione
      </label>
      <div className="mt-2">
        <textarea
          id="event-description"
          name="description"
          placeholder="Descrivi il tuo evento fornendo tutte le informazioni utili"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description}
          rows={3}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        {formik.touched.description && formik.errors.description ? (
          <div className="text-red-600">{formik.errors.description}</div>
        ) : null}
      </div>
      <p className="mt-3 text-sm leading-6 text-gray-600">
        Descrivi il tipo di evento e fornisci tutte le informazioni utili .
      </p>
    </div>
  );
}

function EventImageUpload({
  formik,
  eventSlug,
  disabled,
}: {
  formik: any;
  eventSlug: string;
  disabled: boolean;
}) {
  const { uploadedUrl, error, isUploading, handleFileUpload } = useFileUpload(
    eventSlug,
    uploadEventFile
  );

  useEffect(() => {
    if (uploadedUrl) {
      formik.setFieldValue("images", [uploadedUrl]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedUrl]);

  return (
    <div className="col-span-full">
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Foto di copertina
      </label>
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
        <div className="text-center">
          {formik.values.images[0] ? (
            <div className="relative mx-auto h-46 w-46">
              <img
                src={formik.values.images[0]}
                alt="Uploaded file"
                className="h-full w-full object-cover"
              />
              {!isUploading && (
                <>
                  <ImageInput
                    buttonLabel="Cambia immagine"
                    formik={formik}
                    disabled={disabled}
                    MAX_FILE_SIZE={MAX_FILE_SIZE}
                    handleFileUpload={handleFileUpload}
                    uploadedUrl={uploadedUrl}
                  />
                </>
              )}
            </div>
          ) : (
            <>
              <PhotoIcon
                aria-hidden="true"
                className="mx-auto h-12 w-12 text-gray-300"
              />
              {!formik.values.title && (
                <span className="text-red-400">
                  Imposta il nome dell'evento prima di caricare l'immagine
                </span>
              )}
              <div className="mt-4 flex flex-col items-center text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <ImageInput
                    formik={formik}
                    disabled={disabled}
                    MAX_FILE_SIZE={MAX_FILE_SIZE}
                    handleFileUpload={handleFileUpload}
                    uploadedUrl={uploadedUrl}
                  />
                </label>

                {/* <p className="pl-1">o trascinalo qui</p> */}
              </div>

              <p className="text-xs leading-5 text-gray-600">
                PNG, JPG, GIF fino a {MAX_FILE_SIZE / (1024 * 1024)}MB
              </p>
            </>
          )}
          {isUploading && <p className="text-sm text-gray-600">Uploading...</p>}
          {formik.touched.fileUpload && formik.errors.fileUpload ? (
            <div className="text-red-800">{formik.errors.fileUpload}</div>
          ) : null}
          {error && <div className="text-red-800">{error}</div>}
        </div>
      </div>
    </div>
  );
}

function MaxEventSubscribers({ formik }: { formik: any }) {
  return (
    <div className="sm:col-span-full mt-3">
      <div className="sm:col-span-4">
        <label
          htmlFor="max-subscribers"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Numero massimo di partecipanti (0 uguale nessun limite)
        </label>
        <div className="mt-2">
          <input
            id="max-subscribers"
            name="maxSubscribers"
            type="number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.maxSubscribers}
            placeholder="Inserisci quanti partecipanti possono partecipare al massimo"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            min="0"
          />
          {formik.touched.maxSubscribers && formik.errors.maxSubscribers ? (
            <div className="text-red-600">{formik.errors.maxSubscribers}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function AgeRange({ formik }: { formik: any }) {
  const handleAgeRangeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    const [min, max] = value.split("-").map(Number);
    formik.setFieldValue("ageRange.min", min);
    formik.setFieldValue("ageRange.max", max);
  };
  return (
    <div className="sm:col-span-full mt-3">
      <div className="sm:col-span-4">
        <label
          htmlFor="country"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Range età dei partecipanti
        </label>
        <div className="mt-2">
          <select
            id="age-ranges"
            name="ageRange"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            onChange={(event) => {
              //  formik.handleChange(event);
              handleAgeRangeChange(event);
            }}
            onBlur={formik.handleBlur}
            value={`${formik.values.ageRange.min}-${formik.values.ageRange.max}`}
          >
            {/* <option value="">Età ammessa per i partecipanti</option>*/}
            <option value={"18-99"}>Tuttə (adultə)</option>
            {/* <option value="25-30">25-30</option>
            <option value="31-40">31-40</option>
            <option value="41-55">41-55</option>
            <option value="55-99">più di 55</option> */}
          </select>
          {formik.touched.ageRange?.min && formik.errors.ageRange?.min ? (
            <div className="text-red-600">{formik.errors.ageRange.min}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function EventsKids({ formik }: { formik: any }) {
  const handleKidsAgeRangeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    if (value) {
      const [min, max] = value.split("-").map(Number);
      formik.setFieldValue("kidsAgeRange.min", min);
      formik.setFieldValue("kidsAgeRange.max", max);
    } else {
      formik.setFieldValue("kidsAgeRange.min", null);
      formik.setFieldValue("kidsAgeRange.max", null);
    }
  };
  return (
    <div className="sm:col-span-full">
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-gray-900">
          Evento con figliə?
        </legend>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Indica se l'evento che stai proponendo è aperto ai figliə dei
          partecipanti .
        </p>
        <div className="mt-6 space-y-6">
          <div className="flex items-center gap-x-3">
            <button
              id="with-kids"
              type="button"
              className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                formik.values.allowKids
                  ? "bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline-blue-600"
                  : "bg-white text-blue-600 hover:bg-gray-100 focus-visible:outline-gray-300"
              }`}
              name="allowKids"
              onClick={() => formik.setFieldValue("allowKids", true)}
            >
              Con figliə
            </button>
            <button
              id="without-kids"
              type="button"
              className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                !formik.values.allowKids
                  ? "bg-blue-600 text-white hover:bg-blue-500 focus-visible:outline-blue-600"
                  : "bg-white text-blue-600 hover:bg-gray-100 focus-visible:outline-gray-300"
              }`}
              name="allowKids"
              onClick={() => formik.setFieldValue("allowKids", false)}
            >
              Senza figliə
            </button>
          </div>

          {formik.values.allowKids && (
            <div className="flex items-center gap-x-3">
              <select
                id="kids-age-ranges"
                name="kidsAgeRange"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                onChange={(event) => {
                  formik.handleChange(event);
                  handleKidsAgeRangeChange(event);
                }}
                onBlur={formik.handleBlur}
                value={
                  formik.values.kidsAgeRange.min !== null &&
                  formik.values.kidsAgeRange.max !== null
                    ? `${formik.values.kidsAgeRange.min}-${formik.values.kidsAgeRange.max}`
                    : ""
                }
              >
                <option value="">Età ammessa per i figliə</option>
                <option value="0-18">Tuttə (Figliə)</option>
                <option value="3-5">3-5 anni</option>
                <option value="6-10">6-10 anni</option>
                <option value="11-13">11-13 anni</option>
                <option value="14-16">14-16 anni</option>
                <option value="16-18">16-18 anni</option>
              </select>
              {formik.touched.kidsAgeRange &&
                (formik.errors.kidsAgeRange?.min ||
                  formik.errors.kidsAgeRange?.max) && (
                  <div className="text-red-600">
                    {formik.errors.kidsAgeRange.min ||
                      formik.errors.kidsAgeRange.max}
                  </div>
                )}
            </div>
          )}
        </div>
      </fieldset>
    </div>
  );
}
